import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { encode } from 'js-base64';
import { styled, Unstable_Grid2 as Grid } from '@mui/material';

import { EventError, EventPending, NotificationBox, Promotions } from 'components';
import { Container, Notification } from 'components/shared';

import { FooterLogoIcon, KakaoIcon, NewsletterIcon } from 'components/icons';
import { EventButton } from 'components/event';
import { getBuildingTypeAsync, getDeviceInfoAsync, resolveAsync, selectInitiator } from 'features/initiator';
import { getAsync, GetOption, getPreviewAsync } from 'features/promotions';
import { getAnnouncementsAsync, getAnnouncementsFromHadesAsync } from 'features/announcements';

import { InitiatorResponse } from 'models';

interface FormProps {
  item: InitiatorResponse;
  qrCode?: string;
  sn?: string;
}

interface PackageFormProps {
  eventType: string;
  halfPackage: string;
  seq: string;
}

const Background = styled(Grid)(() => ({
  backgroundColor: '#FFF',
  paddingTop: '32px',
}));

const FooterContainer = styled(Container)(({ theme }) => ({
  marginTop: '20px',
  [theme.breakpoints.up('tablet')]: {
    marginTop: '32px',
  },
  [theme.breakpoints.down('tablet')]: {
    margin: '32px 20px 0px',
    maxWidth: '100%',
  },
}));

const FooterLogoBox = styled('div')(() => ({
  float: 'right',
  marginTop: '24px',
  marginBottom: '48px',
}));

const EventButtons = styled('div')(() => ({
  boxShadow: '0px 0px 12px rgba(27, 29, 31, 0.12)',
  borderRadius: '8px',
  overflow: 'hidden',
}));

const EventTitle = styled('span')(({ theme }) => ({
  lineHeight: '140%',
  [theme.breakpoints.up('tablet')]: {
    lineHeight: '100%',
  },
}));

const MobileOnlyBr = styled('br')(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    display: 'none',
  },
}));

const HiddenForm = ({ item, qrCode = '', sn = '' }: FormProps) => {
  const action = `${process.env.REACT_APP_HOMEPAGE_SERVICE_API_URL}/@ajax/fmk_mem_check_QR.php`;
  return (
    <form id="fmk" method="post" action={action}>
      <input type="hidden" name="sn" value={encode(sn)} />
      <input type="hidden" name="cd" value={encode(qrCode)} />
      <input type="hidden" name="apt_dong" value={encode(item.dong)} />
      <input type="hidden" name="apt_ho" value={encode(item.hogi)} />
      <input type="hidden" name="half" value={encode(item.packageNo)} />
      <input type="hidden" name="utm_medium" value={item.utmMedium} />
      <input type="hidden" name="utm_campaign" value={item.utmCampaign} />
      <input type="hidden" name="utm_term" value={item.utmTerm} />
      <input type="hidden" name="event_list" value="" />
      <input type="hidden" name="redirect" value="" />
    </form>
  );
};

const HiddenPackageForm = ({ eventType, halfPackage, seq }: PackageFormProps) => {
  let redirectUrl = '';
  if (eventType === '1' || eventType === '2') {
    redirectUrl = '/@ajax/fmk_mem_check_QR.php';
  } else if (eventType === '3') {
    redirectUrl = '/livebenefit/comments.html';
  }
  const action = `${process.env.REACT_APP_HOMEPAGE_SERVICE_API_URL}${redirectUrl}`;
  return (
    <form id="fmkPackage" method="post" action={action}>
      <input type="hidden" id="half" name="half" value={encode(halfPackage)} />
      <input type="hidden" id="event_type" name="event_type" value={encode(eventType)} />
      <input type="hidden" id="seq" name="seq" value={encode(seq)} />
    </form>
  );
};

/*
  1. QR 코드로 FMS에서 단지정보 조회 2 api 호출
    - buildingType 가저오는 API
    - 단지 정보 조회 하는 API  상품, 패키지, 
      - sn
        cd
        apt_dong
        apt_ho
        half
        event_list
        redirect
  2. 지생권이면 하데스에 공지사항 조회 API 아니면 FMS에서 공지사항 조회
*/

const EventPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sn = searchParams.get('sn');
  const cd = searchParams.get('cd');
  const promotionId = searchParams.get('promotionId');
  const halfPackage = searchParams.get('package');
  const eventType = searchParams.get('event_type');
  const seq = searchParams.get('seq');

  const { item, qrCodeResolveResult } = useAppSelector(selectInitiator);

  console.log('item, qrCodeResolveResult', item, qrCodeResolveResult);

  useEffect(() => {
    if (halfPackage === 'half1' || halfPackage === 'half2' || halfPackage === 'other' || halfPackage === 'busan') {
      const form = document.getElementById('fmkPackage') as HTMLFormElement;
      if (form) {
        form.submit();
      }
    } else if (cd || sn) {
      let payload = {};
      if (cd) {
        payload = { cd, qrCode: cd };
      } else {
        payload = { sn };
      }
      dispatch(resolveAsync(payload));
      // FMS 빌딩 테이블에 있는 빌딩 타입 가져옴
      dispatch(getBuildingTypeAsync(payload));
      if (sn) {
        dispatch(getDeviceInfoAsync(sn));
      }
    } else if (promotionId) {
      dispatch(getPreviewAsync(promotionId));
    }
  }, [cd, promotionId, halfPackage, sn]);

  useEffect(() => {
    if (qrCodeResolveResult === 'success' && item) {
      let packageNumber = item.packageNo;
      if (item.buildingProductCode !== 'S') {
        packageNumber = '0';
      }

      const payload: GetOption = {
        buildingId: item.buildingId,
        code: item.buildingProductCode || 'S',
        packageNumber,
      };
      dispatch(getAsync(payload));
      if ((payload.code === 'G' || payload.code === 'S') && sn) {
        dispatch(getAnnouncementsFromHadesAsync(sn as string));
      } else {
        dispatch(getAnnouncementsAsync(item.krBuildingCode));
      }
    }
  }, [qrCodeResolveResult]);

  if (qrCodeResolveResult === 'failed') {
    return <EventError cd={cd} sn={sn} />;
  } else if (qrCodeResolveResult === 'pending') {
    return <EventPending />;
  }
  return (
    <>
      <Background>
        <Grid justifyContent="center">
          <NotificationBox />
          <Promotions />
        </Grid>
        <Grid container justifyContent="center" sx={{ background: '#F7F8F9' }}>
          {item && <HiddenForm item={item} qrCode={cd || ''} sn={sn || ''} />}
          <HiddenPackageForm eventType={eventType || ''} halfPackage={halfPackage || ''} seq={seq || ''} />
          <FooterContainer>
            <EventButtons>
              <EventButton
                key="1"
                subTitle="입주민을 위한 혜택이 가득!"
                icon={<KakaoIcon />}
                onClick={() => (window.location.href = 'https://bit.ly/kakaotalkchannel-focusmedia')}
              >
                <>{'카카오톡 채널 추가하기'}</>
              </EventButton>
              <EventButton
                key="2"
                subTitle={
                  <EventTitle>
                    공식 블로그에서&nbsp;
                    <MobileOnlyBr />
                    알려드려요!
                  </EventTitle>
                }
                icon={<NewsletterIcon />}
                onClick={() => (window.location.href = 'https://blog.naver.com/focusmedia_official')}
              >
                <>{'포커스미디어 최신 소식보기'}</>
              </EventButton>
              {/* 오피스인지 아닌 건물이면서 매체가 25인치 1세대가 아니면, 시청자 대표 버튼 표시 */}
              {/* {item?.buildingProductCode === 'S' &&
                buildingType !== 'O' &&
                deviceType !== '' &&
                deviceType !== 'UGOOS-UT6' && (
                  <EventButton
                    key="3"
                    subTitle="매월 쏠쏠한 혜택"
                    icon={<ViewerIcon />}
                    onClick={() => {
                      const form = document.getElementById('fmk') as HTMLFormElement;
                      if (form) {
                        form.event_list.value = '4';
                        form.submit();
                      }
                    }}
                  >
                    <>
                      {
                        <EventTitle>
                          엘리베이터TV&nbsp;
                          <MobileOnlyBr />
                          시청자 대표 참여하기
                        </EventTitle>
                      }
                    </>
                  </EventButton>
                )} */}
            </EventButtons>
            <FooterLogoBox>
              <FooterLogoIcon />
            </FooterLogoBox>
          </FooterContainer>
        </Grid>
      </Background>
      <Notification />
    </>
  );
};

export { EventPage };
